.site-footer {

    background: $blue-dark;
    position: relative;
    z-index: 0;

    * {
        color: $white;
        font-family: $main-font6;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }

    a:hover,
    a:focus {
        color: $blue-light;
    }

    strong {
        font-family: $main-font7;
        font-weight: 700;
    }

    address {
        margin-top: 0;
        margin-bottom: 0;
    }

    .footer-logo-mobile-div {
        display: flex;
        justify-content: center;
        margin-top: 40px;
    }

    .footer-col1 img {
        width: 167px;
        object-fit: contain;
    }

    .footer-col3 a {
        text-underline-position: under;
        text-decoration: underline;
    }

    .footer-col4 * {
        opacity: 0.5;
    }

    ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
    }

    .social-links {
        margin-top: 20px;
    }

    .social-links ul {
        margin-left: -10px;
    }

    .social-links ul li {
        display: inline-block;
        padding-left: 10px;
    }

    .social-links ul li a {
        display: inline-block;
        border-radius: 28px;
        background: rgba(255, 255, 255, 0.12);
        width: 28px;
        height: 28px;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        transition: all 0.25s ease;
        -webkit-transition: all 0.25s ease;
    }

    .social-links ul li a:hover,
    .social-links ul li a:focus {
        background: rgba(255, 255, 255, 0.22);
    }

    .social-links ul li a img {
        width: 14px;
        object-fit: contain;
    }

    padding: {
        top: 60px;
        bottom: 90px;
    }


    &::before {
        content: '';
        display: inline-block;
        background: url('/images/footer-bg.svg') no-repeat 100% 100%;
        position: absolute;
        z-index: 1;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-size: contain;
    }

    > div {
        position: relative;
        z-index: 2;
    }


    @media (max-width: 959px) {

        padding: {
            top: 30px;
            bottom: 45px;
        }

        .footer-col3 .footer-logo-mobile {
            display: none;
        }
    }

    @media (min-width: 768px) {
        .footer-col1 > div {
            text-align: left;
        }
    }

    @media (max-width: 767px) {
        .footer-col1 {
            margin-bottom: 0;
        }
    }
}
