
.privacyverklaring-main-content-table-desktop {
    overflow-x: auto;
}

.privacyverklaring-main-content-table-desktop table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd;
}

.privacyverklaring-main-content-table-desktop table th,
.privacyverklaring-main-content-table-desktop table td {
    text-align: left;
    padding: 8px;
}

.privacyverklaring-main-content-table-desktop table tr:nth-child(even){background-color: #f2f2f2}



@media (min-width: 960px) {
    .legal-main {
        padding-top: 70px;
        padding-bottom: 70px;
    }

    .privacyverklaring-main-content-table-mobile {
        display: none;
    }

}


@media (min-width: 1200px) {
    .legal-main > div.container {
        max-width: 1230px;
    }
}


@media (max-width: 959px) {
    .legal-main {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .privacyverklaring-main-content-table-desktop {
        display: none;
    }

}


