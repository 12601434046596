@import 'vendor/bootstrap/bootstrap';
@import '~uikit/src/scss/variables';
@import '~uikit/src/scss/uikit';
@import 'vendor/fontawesome/fontawesome';
@import 'vendor/slick-slider/slick',
'vendor/slick-slider/slick-theme';

@import "variables/sizes",
"variables/colors",
"variables/fonts";

@import "global/global",
"global/form/checkbox",
"global/form/slider";
@import "layout/header",
"layout/nav",
"layout/footer";

@import "pages/home",
"pages/product",
"pages/legal",
"pages/aboutus",
"pages/contact",
"pages/errorpage",
"pages/blog",
"products/categories",
"products/product-list",
"products/detail",
"products/related";

@import 'checkout/cart';


button:focus {
    outline: none !important;
}

$font1: roboto-condensed, sans-serif;
$font2: lato, sans-serif;

[class*=uk-animation-] {
    animation-duration: 0.5s !important;
}

.prev.showifmorethan4 {
    display: block !important;
    width: 100%;
    height: 20px;
    margin-bottom: 15px;
}

.next.showifmorethan4 {
    display: block !important;
    width: 100%;
    height: 20px;
    margin-top: 15px;
}


