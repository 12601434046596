
.cart-content-header {
    /*border-bottom: 1px solid #cccccc;*/
}
.cart-content-row {
    & + .cart-content-row {
        /*border-top: 1px solid #cccccc;*/
    }
}

.cart-contents {
    .delete-link {
        color: rgba(0,0,0,0.3);
        text-decoration: none;
        &:hover {
            color: $main-color;
        }
    }
}

.cart-totals {
    .total-amount {
        /*border:  1px solid #cccccc;
        background-color: #f9f9f9;*/
      /*  border: 1px solid rgba(7, 41, 57, 0.10);
        background: rgba(7, 41, 57, 0.06);
        border-radius: 4px;*/
        padding: {
            top: 10px;
            bottom: 10px;
        }
        & + .total-amount {
          /*  border-top: none;*/

        }
    }
}

.proceed-button {
    position: relative;
    z-index: 1;
    a {
        @extend .uk-button;
        @extend .order-btn;
    }
}

section.cart-header {
    padding: {
        top: 60px;
        bottom: 60px;
    }
    position: relative;
    background: {
        image: url("/images/home-intro-bg.jpg");
        size: cover;
        position: center;
    }
    &:before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $dark-bg-color;
        opacity: .8;
    }
    h1 {
        z-index: 2;
        position: relative;
        margin-top: 110px;
        display: inline-block;
        font-size: 48px;
        color: $light-font-color;
        &:after {
            content: "shop";
            z-index: -1;
            position: absolute;
            bottom: 0;
            right: 50px;
            color: $main-color;
            font: {
                family: $hand-font;
                size: 100px;
                weight: normal
            }
        }
    }
}

body.cart-page, body.checkout-page {
    position: relative;
    @include media-breakpoint-up(lg) {
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 50%;
            height: 100%;
            background: {
                image: url("/images/bg-trending-left.png");
                repeat: repeat-y;
            }
        }

        &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            width: 50%;
            height: 100%;
            transform: scaleX(-1);
            background: {
                image: url("/images/bg-trending-left.png");
                repeat: repeat-y;
                position: left;
            }
        }
    }

}

.checkout-page {
.cart-totals .total-amount:last-of-type {
    position: relative;
}

}

.form-steps {
    list-style-type: none;
    padding-left: 0px;
    margin-left: -20px;
    li {
        display: inline-block;
        padding-left: 20px;
        margin-right: 5px;
        a, span {
            display: inline-block;
            padding: 10px 10px;
            color: $blue-dark;
        }
        &.enabled, &.current {
                color: $blue-light;
            a, span {
                color: $blue-light;
            }
        }

    }
}




.cart-content-section, .checkout-page-form {
    padding-top: 40px;
    padding-bottom: 15px;
}

.cart-content-section .cart-totals, .checkout-page-form .cart-totals {
    margin-right: 15px;
}

.cart-content-section .cart-totals > .cart-content-header > div, .checkout-page-form .cart-totals .cart-content-header > div {
    padding-left: 0;
    padding-right: 0;
}

.cart-content-section .cart-content-row > .cart-content-row, .checkout-page-form-block {
    border: 1px solid rgba(7, 41, 57, 0.10);
    background: rgba(7, 41, 57, 0.06);
    padding: 15px;
    border-radius: 4px;
}

.cart-content-section .cart-contents > .row > .cart-content-row + .cart-content-row {
    margin-top: 40px;
}

.cart-totals .cart-content-main {
    border: 1px solid rgba(7, 41, 57, 0.10);
    background: rgba(7, 41, 57, 0.06);
    border-radius: 4px;
}

.cart-totals .cart-content-main > div > div + div {
    border-top: 1px solid rgba(7, 41, 57, 0.10);
}

.cart-totals .proceed-button a {
    color: #FFF;
    border-radius: 60px;
    background-color: $blue-dark;
    border-color: $blue-dark;
    padding: 11px 28px;
    font-family: $main-font7;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
/*    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;*/

    transition: all .3s;
    position: relative;
    overflow: hidden;
    z-index: 1;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        border-radius: 60px;
        z-index: -2;
    }
    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: darken($blue-light, 15%);
        transition: all .3s;
        border-radius: 60px;
        z-index: -1;
    }
    &:hover {
        color: #FFF;
        &:before {
            width: 100%;
        }
    }

}

.cart-totals .proceed-button a:hover,
.cart-totals .proceed-button a:focus {
    //background-color: $blue-light;
    border-color: $blue-light;
}

.cart-nav-top {
    padding-top: 70px;
}

.form-steps li a:hover,
.form-steps li a:focus {
    text-decoration: underline;
    text-underline-position: under;
}

.remarks-row {
    margin-top: 0px !important;
}


.checkout-page-form-block + .checkout-page-form-block {
    margin-top: 40px;
}

.checkout-page-form-block > div.my-4 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.checkout-page-form-block .password-fields.show {
    margin-top: 30px !important;
}

/*.checkout-page-form-block .form-group:last-child, .checkout-page-form-block .form-group:last-child label {
    margin-bottom: 0px !important;
}*/

.checkout-page-form .create_account, .checkout-page-form .create_account label,
.checkout-page-form .shipping-type-shipping .form-group, .checkout-page-form .shipping-type-shipping .form-group label,
.remarks-row .form-group, .remarks-row .form-group label,
.checkout-page-form .payment-form, .checkout-page-form .payment-form label {
    margin-bottom: 0px !important;
}

.checkout-page-form .shipping-type-shipping .form-group {
    margin-bottom: 1rem !important;
}

.transport-cost-notification, #shipping_type_pickup_notification {
    display: none;
}

@media (min-width: 992px) {
    .cart-totals-html-mobile {
        display: none;
    }
}

@media (min-width: 1200px) {
    .cart-nav-top,
    .cart-content-section,
    .checkout-page-form {
        max-width: 1230px;
    }

}


@media (max-width: 991px) {
    .cart-totals-html-desktop {
        display: none;
    }
    .cart-totals-html-mobile {
        border: 0px !important;
        background: transparent !important;
        padding: 0px !important;
    }

    .cart-totals-html-mobile .cart-totals {
        padding-left: 15px !important;
    }

    .cart-page-cart-content .cart-page-cart-content-totals {
        margin-top: 40px;
    }

    .cart-page-cart-content .cart-page-cart-content-totals > .cart-totals {
        padding-left: 15px !important;
    }
}


@media (min-width: 992px) and (max-width: 1200px) {
    .cart-totals-html-desktop {
        margin-left: 0;
        flex: 0 0 33.333%;
        max-width: 33.333%;
    }
}

@media (min-width: 960px) and (min-height: 1048px) {
    .confirmation-section {
        min-height: calc(100svh - 848px);
    }
}

@media (max-width: 959px) {
    .sp-checkout-section .cart-nav-top, .cart-section .cart-nav-top {
        padding-top: 40px;
    }
    .sp-checkout-section .checkout-page-form, .cart-section .cart-content-section {
        padding-top: 0px;
    }
}


@media (max-width: 767px) {
    .cart-content-section .cart-content-row .col-md-8 {
        padding-top: 20px;
    }
}

@media (max-width: 680px) {
    .form-steps li {
        display: block;
    }
    .form-steps li + svg, .form-steps li + i {
        display: none;
    }
}
