section#home-intro {
    background-color: $pastel-1;
    position: relative;
    height: 800px;
    color: $pastel-1-accent;
    .content-div {
        position: relative;
        z-index: 10;
        font-size: 20px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        line-height: 28.13px;
    }
    .container {
        height: 100%;
        color: $black;
    }
    .filled-image {
        position: absolute;
        height: 800px;
        right: 0px;
        padding-left: 0;
        padding-right: 0;
        top: 0;
        >div {
            text-align: right;
        }
        img {
            height: 800px;
           object-fit: cover;
        }
    }
    &:before {
        position: absolute;
        z-index: 1;
        width: 45%;
        height: 80%;
        bottom: 0;
        left: 0;
        color: $pastel-1-accent;
        content: "";
        background-image: url(/images/w.svg);
        background-size: 100%;
        background-position: bottom left;
        background-repeat: no-repeat;
        opacity: .20;
    }
    .uk-button.uk-button-default:hover {
        background-color: #87a4a8;
        color: $white;
    }
}

section#home-about {
    background-color: $pastel-2;
    position: relative;
    height: 700px;
    color: $pastel-1-accent;
    .content-div {
        position: relative;
        z-index: 10;
        font-size: 20px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        line-height: 28.13px;
        color:$white;
        h2 {
            font-size: 48px;
            font-weight: 700;
            color: $white;
        }
        .uk-button.uk-button-default:hover {
            background-color: #5c6a75;
            color: $white;
        }

    }
    .container {
        height: 100%;
        color: $black;
    }
    .filled-image {
        position: absolute;
        height: 700px;
        left: 0px;
        padding-left: 0;
        padding-right: 0;
        top: 0;
        >div {
            text-align: left;
        }
        img {
            height: 700px;
            object-fit: cover;
        }
    }
    &:before {
        position: absolute;
        z-index: 1;
        width: 45%;
        height: 90%;
        bottom: 0;
        right: 0;
        color: $pastel-2-accent;
        content: "";
        background-image: url(/images/w2.svg);
        background-size: 100%;
        background-position: bottom right;
        background-repeat: no-repeat;
        opacity: .20;
    }
}

section#new-products {
    @extend .py-5;
    @extend .mb-4;
    .uk-position-small[class*=uk-position-center-left]{

        background-color: $pastel-2;
        color: $white;
        padding: 11px 20px ;
        transform: translateX(-120%) translateY(30px);
        font-size: 25px;

    }
    .uk-position-small[class*=uk-position-center-right] {

        background-color: $pastel-2;
        color: $white;
        padding: 11px 20px ;
        transform: translateX(120%) translateY(30px);
        font-size: 25px;

    }
    .new-content > div:first-of-type {

        text-align: center;
        @extend .mb-5;

    }
    h2 {

        display:inline-block;
        margin: 0 auto;
        text-align: center;
        text-transform: uppercase;
        position: relative;
        //border-bottom: 3px dashed $pastel-2;

        &:after {

            content: "";
            position: absolute;
            bottom: -10px;
            left: -10px;
            width: calc(100% + 10px);
            height: 3px;
            background-image: linear-gradient(to right, transparent 50%, $pastel-2 50%);
            background-size: 22px 100%;

        }

    }

    li {
        padding-top: 40px;

        .new-product-item {
            position: relative;
            display: block;
            &:before {

                content: "";
                position: absolute;
                top: -30px;
                left: calc(50% - 50px);
                width: 50%;
                padding-bottom: 24%;
                background-image: url(/images/tape.svg?052e661…);
                background-size: 100%;
                background-position: bottom center;
                z-index: 999;
                background-repeat: no-repeat;
                transform-origin: center;
                opacity: .8;

            }

        }
        &:nth-child(2n) .new-product-item:before {

            //left: 50px;
            transform: scaleX(-1);

        }
        &:nth-child(3n) .new-product-item:before {

            //left: 50px;
            transform:  rotate(20deg);

        }
        &:nth-child(4n) .new-product-item:before {

            //left: 50px;
            transform: rotate(-20deg) scaleX(-1);

        }

    }

}

section#home-social {
    background-color: $pastel-3;
    background-image: url(/images/plane.svg);
    background-repeat: no-repeat;
    background-size: 350px;
    background-position: 0 -55px ;
    height: 240px;
    @extend .py-4;
    @extend .d-flex;
    @extend .align-items-center;
    h3 {
        font-size: 32px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: normal;
    }
    h4 {
        margin-top: -15px;
        font-size: 32px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
    }

    .social-buttons {
        ul {
            @extend .row;
            @extend .px-0;
            list-style-type: none;
            li {
                @extend .col-5;
                @extend .py-3;
                &:nth-child(3) {
                    @extend .offset-lg-1;
                }
                a {
                    @extend .uk-button;
                    @extend .w-100;
                    background-color: $white;
                    box-shadow: 0 0 15px $pastel-3-accent;
                    border-radius: 5px;
                    font-size: 18px;
                    padding-top: 7px;
                    padding-bottom: 7px;
                    transition: all 300ms ease-in-out;
                    &:hover {
                        background-color: $button-dark;
                        color: $white;
                    }
                    i {
                        font-size: 20px;
                    }
                }
            }
        }
    }
}

section#home-designs {
    background-color: $pastel-1;
    position: relative;
    height: 800px;
    color: $pastel-1-accent;
    .content-div {
        position: relative;
        z-index: 10;
        font-size: 20px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        line-height: 28.13px;
        h2 {
            font-size: 48px;
            font-weight: 700;
        }
        .uk-button.uk-button-default:hover {
            background-color: #f7cc49;
            color: $black;
        }
    }
    .container {
        height: 100%;
        color: $black;
    }
    .filled-image {
        position: absolute;
        height: 800px;
        right: 0px;
        padding-left: 0;
        padding-right: 0;
        top: 0;
        >div {
            text-align: right;
        }
        img {
            height: 800px;
            object-fit: cover;
        }
    }
    &:before {
        position: absolute;
        z-index: 1;
        width: 45%;
        height: 80%;
        bottom: 0;
        left: 0;
        color: $pastel-1-accent;
        content: "";
        background-image: url(/images/w.svg);
        background-size: 100%;
        background-position: bottom left;
        background-repeat: no-repeat;
        opacity: .20;
    }
}




.header-home {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    background: transparent;
    box-shadow: none;
}


.header-home.uk-sticky-fixed {
    position: fixed !important;
    background: $blue-dark;
    animation-duration: 0s !important;
    transition: 0s !important;
}

.header-home.uk-sticky-fixed + .uk-sticky-placeholder {
    /*height: 108px !important;*/
    animation-duration: 0s !important;
    transition: 0s !important;
    position: fixed !important;
}

.header-home-section {
    position: relative;
    z-index: 0;
}

.header-home-section::before {
    content: '';
    background: url('/images/crowd-people-dance-floor-pink-blue-light-1.svg'), lightgray;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
}

.header-home-section::after {
    content: '';
    //background: linear-gradient(180deg, #072939 0%, rgba(7, 41, 57, 0.00) 100%);
    background: rgba(7, 41, 57, 0.79);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
}

.header-home-section > div {
    position: relative;
    z-index: 3;
    max-width: 1230px;
    padding-top: 245px;
    padding-bottom: 125px;
}

.header-home-section > div::before {
    content: '';
    //background: url('/images/moving-head-top.png') no-repeat 100% 0%;
    background: url('/images/light-header.png') no-repeat 100% 0%;
    position: absolute;
    top: 0;
    right: 120px;
    z-index: 4;
    width: 274px;
    height: 319px;
    background-size: contain;
    animation: move 5s linear infinite;
    -webkit-animation: move 5s linear infinite;
    -moz-animation:move 5s linear infinite;
}

@-moz-keyframes rotate {
    0% {
        -moz-transform: rotate(0deg);
    }

    25% {
        -moz-transform: translateX(-1px);
    }

    50% {
        -moz-transform: translateX(0);
    }

    75% {
        -moz-transform: translateX(1px);
    }

    100% {
        -moz-transform: translateX(0);
    }
}

@-webkit-keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg);
    }

    25% {
        -webkit-transform: translateX(-1px);
    }

    50% {
        -webkit-transform: translateX(0);
    }

    75% {
        -webkit-transform: translateX(1px);
    }

    100% {
        -webkit-transform: translateX(0);
    }
}


@keyframes move {
    0% {
        transform: translateX(0);
        -webkit-transform: translateX(0);
    }

    25% {
        transform: translateX(-1px);
        -webkit-transform: translateX(-1px);
    }

    50% {
        transform: translateX(0);
        -webkit-transform: translateX(0);
    }

    75% {
        transform: translateX(1px);
        -webkit-transform: translateX(1px);
    }

    100% {
        transform: translateX(0);
        -webkit-transform: translateX(0);
    }
}



.header-home-section > div::after {
    content: '';
    /*background: url('/images/moving-head-top-light.png') no-repeat 0 100%;*/
    background: url('/images/moving-head-top-light-1.png') no-repeat 100% 0;
    position: absolute;
    /*top: 23px;
    right: -72px;*/
    top: -20px;
    right: -60px;
    z-index: 5;
    width: 100%;
    height: 100%;
    background-size: cover;
    opacity: 0.2;
    animation: rotate 5s linear infinite;
    -webkit-animation: rotate 5s linear infinite;
    -moz-animation:rotate 5s linear infinite;
    transform-origin: 90% 10%;
    -webkit-transform-origin: 90% 10%;
}


@-moz-keyframes rotate {
    0% {
        -moz-transform: rotate(0deg);
    }

    25% {
        -moz-transform: rotate(1.5deg);
    }

    50% {
        -moz-transform: rotate(0deg);
    }

    75% {
        -moz-transform: rotate(-1.5deg);
    }

    100% {
        -moz-transform: rotate(0deg);
    }
}
@-webkit-keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg);
    }

    25% {
        -webkit-transform: rotate(1.5deg);
    }

    50% {
        -webkit-transform: rotate(0deg);
    }

    75% {
        -webkit-transform: rotate(-1.5deg);
    }

    100% {
        -webkit-transform: rotate(0deg);
    }
}



@keyframes rotate {
    0% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
    }

    25% {
        transform: rotate(1.5deg);
        -webkit-transform: rotate(1.5deg);
    }

    50% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
    }

    75% {
        transform: rotate(-1.5deg);
        -webkit-transform: rotate(-1.5deg);
    }

    100% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
    }
}


.header-home-section > div > div {
    position: relative;
    z-index: 6;
}

.header-home-section h1 .blue {
    font-family: $main-font6;
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    line-height: 52px;
    text-transform: uppercase;
    color: $blue-light;
    display: block;
}

.header-home-section h1 .white {
    font-family: $main-font9;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 54px;
    letter-spacing: -1.2px;
    color: $white;
    display: block;
}

.header-home-section h1 + p {
    margin-top: 25px;
}


.home-1 {
    padding-top: 70px;
}


.productslider hr {
    margin-top: 70px;
    border-top: 1px solid rgba(7, 41, 57, 0.10);
}

.productslider > div.container, #related-products > div.container {
    max-width: 1254px;
    margin-left: auto;
    margin-right: auto;
}


.productslider .uk-slider-container, #related-products .uk-slider-container {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.productslider .uk-slider-items, .related-product-slider .uk-slider-items {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.productslider .image-container, #related-products .image-container {
    height: 200px;
    position: relative;
}

.productslider .image-container img, #related-products .image-container img {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.productslider .product-name, #related-products .product-name {
    margin-top: 20px;
    min-height: 40px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.productslider .product-name span, #related-products .product-name span {
    display: inline-block;
    max-width: 130px;
    margin-left: auto;
    margin-right: auto;
    font-family: $main-font7;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    color: $main-color;
}

.productslider .product-price, #related-products .product-price {
    margin-top: 10px;
    font-family: $main-font6;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: $main-color;
}

.productslider .product-link, #related-products .product-link {
    margin-top: 15px;
}

.productslider .product-link a, #related-products .product-link a {
    font-family: $main-font6;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: $main-color;
    text-decoration: underline;
    text-underline-position: under;
}

.productslider .product-link a:hover,
.productslider .product-link a:focus,
#related-products .product-link a:hover,
#related-products .product-link a:focus {
    color: $blue-light;
}

.productslider .uk-slidenav svg, #related-products .uk-slidenav svg {
    display: none;
}

.productslider .uk-slidenav, #related-products .uk-slidenav {
    visibility: visible !important;
    display: block !important;
}

.productslider .uk-slidenav img, #related-products .uk-slidenav img {
    width: 25px;
    height: 25px;
    object-fit: contain;
}


.home-producten-all  {
    padding-top: 40px;
    padding-bottom: 50px;
}

.home-2 {
    padding-top: 165px;
}

.usps-home {
    position: relative;
    z-index: 0;
    padding-top: 100px;
    padding-bottom: 60px;
}

.usps-home::before {
    content: '';
    background: url('/images/dark-bg-layer.svg') no-repeat 50% 50%;
    background-size: cover;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.usps-home > div {
    position: relative;
    z-index: 2;
    max-width: 1200px;
}

.usps-home > div.container > div.row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.usps-home .col-md-4 > div,
.usps-home .col-md-5 > div {
    position: relative;
}

.usps-home .col-md-4 > div img,
.usps-home .col-md-5 > div img {
    position: absolute;
    top: calc(50% + 77px);
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}


/*
.usps-home h2 {
    max-width: 250px;
}*/

.usps-home .onze-aanpak-p {
    margin-top: 25px;
}

.usps-home-ul {
    margin-top: 40px;
    margin-left: -45px;
    list-style: none;
}

.usps-home-ul li {
    padding-left: 60px;
    display: inline-block;
    position: relative;
    z-index: 0;
    max-width: 210px;
    font-size: 14px;
    text-transform: uppercase;
}

.usps-home-ul li:nth-child(1)::before {
    content: '';
    display: inline-block;
    background: url('/images/technology-1.svg') no-repeat 50% 50%;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    left: 30px;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-size: contain;
}

.usps-home-ul li:nth-child(2) {
    max-width: 190px;
}

.usps-home-ul li:nth-child(2)::before {
    content: '';
    display: inline-block;
    background: url('/images/consultant-services-1.svg') no-repeat 50% 50%;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    left: 30px;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-size: contain;
}

.usps-home-ul li:nth-child(3)::before {
    content: '';
    display: inline-block;
    background: url('/images/business-1.svg') no-repeat 50% 50%;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    left: 30px;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-size: contain;
}

.counter {
    padding-top: 95px;
    padding-bottom: 85px;
}

.counter h2 {
    max-width: 656px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
}

.counter ul {
    list-style: none;
    margin-left: -125px;
    padding-left: 0px;
}

.counter ul li {
    display: inline-block;
    padding-left: 125px;
}

.counter ul li span {
    display: block;
}

.counter .li-top {
    font-family: $main-font9;
    font-size: 52px;
    font-style: normal;
    font-weight: 600;
    line-height: 62px;
    letter-spacing: -2px;
    margin-bottom: 15px;
}

.counter .li-center {
    font-family: $main-font8;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.counter .li-bottom {
    font-family: $main-font10;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.counter hr {
    border-top: 1px solid #E4E4E7;
    max-width: 809px;
    margin-left: auto;
    margin-top: 40px;
    margin-right: auto;
    margin-bottom: 30px;

}

/*
.boek-je-demo {
    content: "Boek je demo!";
}*/

.boek-je-demo {
    min-width: 250px;
}

.boek-je-demo::before {
    content: "Vrijblijvend aan te vragen.";
    font-size: 15px;
    display: none;
}

.boek-je-demo:hover {
    /*display: none;*/
    font-size: 0px;
}

.boek-je-demo:hover::before {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    font-size: 15px;
}


@media (min-width: 1440px) {

    .usps-home .col-md-4 > div img,
    .usps-home .col-md-5 > div img {
        /*width: 572px !important;*/
        width: 100% !important;
        max-width: none;
        object-fit: cover;
    }
}

@media (max-width: 1439px) {
    .usps-home .col-md-4 > div img,
    .usps-home .col-md-5 > div img {
        position: absolute;
        top: calc(50% + 127px);
    }
}


@media (max-width: 1350px) {
    .productslider > div.container, #related-products > div.container,
    .productslider .uk-slider-container, #related-products .uk-slider-container {
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
}


@media (max-width: 1220px) {
    .header-home-section > div::after {
   /*     top: 26px;
        right: -78px;*/
        top: -20px;
        right: -60px;
    }
}

@media (max-width: 1199px) {

}


@media (max-width: 1180px) {
    .header-home-section > div::after {
        /*top: 28px;
        right: -62px;*/
        top: 0px;
        right: -42px;
    }
}


@media (max-width: 1140px) {
    .header-home-section > div::after {
        /*top: 40px;
        right: -53px;*/
        top: 0px;
        right: -30px;
    }
}


@media (max-width: 1100px) {
    .header-home-section > div::after {
        /*top: 48px;
        right: -36px;*/
        top: 0px;
        right: -30px;

    }
}


@media (max-width: 1060px) {
    .header-home-section > div::after {
        /*top: 48px;
        right: -18px;*/
    }
}


@media (max-width: 1000px) {
    .header-home-section > div::after {
        /*top: 57px;
        right: 0px;*/
    }
}



@media (max-width: 1080px) {
    .usps-home .col-md-4 > div img,
    .usps-home .col-md-5 > div img {
        position: absolute;
        top: calc(50% + 127px);
    }

    .header-home-section h1 .white {
        font-size: 38px;
        line-height: 44px;
    }

}


@media (max-width: 959px) {

    .home-header-logo-mobile a {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
    }

    .header-home > .container-fluid-desktop {
        display: none;
    }

    .usps-home {
        position: relative;
        z-index: 0;
        padding-top: 40px;
        padding-bottom: 20px;
    }
    .usps-home-ul li {
        display: block;
        max-width: none !important;
        line-height: 55px;
    }
    .usps-home .col-md-4 > div img,
    .usps-home .col-md-5 > div img {
        position: absolute;
        top: calc(50% + 227px);
    }

    .product-usps-section hr {
        margin-top: 20px !important;
    }

    .home-1 {
        padding-top: 40px;
    }

    .header-home-section > div::after {
        /*top: 75px;
        right: 15px;*/
        top: 20px;
        right: 0px;
    }



}


@media (min-width: 660px) and (max-width: 959px) {
    header .container-fluid-mobile>div>div.col-8 {
        flex: 0 0 50%;
        max-width: 50%;
    }
}


@media (max-width: 900px) {
    .header-home-section > div::after {
        /*top: 85px;
        right: 25px;*/
        top: 40px;
        right: 30px;
    }

    .header-home-section h1 .white {
        font-size: 28px;
        line-height: 34px;
    }

    .header-home-section h1 .blue {
        line-height: 32px;
    }

}

@media (max-width: 800px) {
    .header-home-section > div::after {
        /*top: 95px;
        right: 45px;*/

        top: 50px;
        right: 40px;

    }
}






@media (max-width: 767px) {


    .header-home-section > div {
        padding-top: 345px;
        padding-bottom: 30px;
    }

    .home-1 > div > div > div:nth-child(2){
        padding-top: 40px;
    }

    .home-2 > div > div > div:nth-child(1){
        order: 2;-webkit-order:2;
        padding-top: 20px;
    }

    .home-2 > div > div > div:nth-child(2){
        order: 1;-webkit-order:1;
    }

    .usps-home .col-md-4 > div img,
    .usps-home .col-md-5 > div img {
        position: relative;
        top: 0px !important;
        left: 0px !important;
        -webkit-transform: none !important;
        -moz-transform: none !important;
        -ms-transform: none !important;
        -o-transform: none !important;
        transform: none !important;
        margin-bottom: -175px;
    }

    .productslider > div.container, #related-products > div.container,
    .productslider .uk-slider-container, #related-products .uk-slider-container {
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
    }


    .counter {
        padding-top: 40px;
        padding-bottom: 20px;
    }

    .counter ul li {
        margin-bottom: 20px;
    }

    .productslider hr {
        margin-top: 20px;
    }

    .home-producten-all {
        padding-bottom: 20px;
    }

}



@media (max-width: 700px) {
    .header-home-section > div::after {
        /*top: 95px;
        right: 65px;*/
        top: 50px;
        right: 60px;
    }
}



@media (max-width: 600px) {
    .header-home-section > div::after {
        /*top: 115px;
        right: 105px;*/
        top: 80px;
        right: 0px;
    }
}


@media (max-width: 500px) {
    .header-home-section > div::before {
        right: 15px;
    }
    /*
    .header-home-section > div::after {
        top: 130px;
        right: 30px;
    }*/
}


@media (max-width: 480px) {
    .header-home-section h1 .blue {
        font-size: 17px;
    }
}
