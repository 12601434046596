
.contact-main .leadix-form .form-group {
    margin-bottom: 20px;
}

.contact-main #form-group-required-privacy-notice input {
    display: none;
}

.contact-main .privacy-notice-paragraph {
    padding-left: 28px;
    position: relative;
    z-index: 0;
}

.contact-main .privacy-notice-paragraph::before {
    position: absolute;
    top: 0;
    left: 0;
}

.contact-main .privacy-notice-paragraph a {
    color: $blue-light;
}

.contact-main .privacy-notice-paragraph a:hover,
.contact-main .privacy-notice-paragraph a:focus {
    color: $blue-dark;
    text-decoration: underline;
    text-underline-position: under;
}

.contact-main .submit {
    color: #fff;
    border-radius: 60px;
    background-color: $blue-light;
    border: 1px solid $blue-light;
    padding: 11px 28px;
    font-family: $main-font7;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    margin-top: 10px;
}

.contact-main .submit:hover,
.contact-main .submit:focus {
    background-color: $blue-dark;
    border: 1px solid $blue-dark;
}


.contact-main-col2 ul {
    list-style: none;
    padding-left: 0px;
}

.contact-main-col2 ul li a {
    color: $blue-light;
}

.contact-main-col2 ul li a:hover,
.contact-main-col2 ul li a:focus {
    color: $blue-dark;
    text-decoration: underline;
    text-underline-position: under;
}

.contact-map {
    margin-top: 40px;
}

@media (min-width: 960px) {
    .contact-main {
        padding-top: 70px;
        padding-bottom: 70px;
    }

    .contact-main-col2 {
        padding-left: 100px;
    }
}

@media (min-width: 1200px) {
    .contact-main > .container {
        max-width: 1230px;
    }
    .contact-bottom .product-usps-section > section.container {
        max-width: 1230px;
    }
}


@media (max-width: 959px) {
    .contact-main {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}


@media (max-width: 767px) {
    .contact-main-col2 {
        padding-top: 40px;
    }
}
