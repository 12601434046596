.product-title {
    font: {
        family: $main-font;
        style: normal;
        weight: 400;
    }
    font-size: 42px;
    font-weight: 400;
    text-transform: none;
    @include media-breakpoint-down(sm) {
        font-size: 1.2rem;
    }
}
.banner {
    background: {
        image: url("/images/related-banner.png");
        size:cover;
    }
}

.product-detail-price {
    color: $main-color;
    line-height: 33.75px;
    font: {
        size: 24px;
        weight: 700;
    }
}

.main-image {
/*    height: 600px;*/
    width: 100%;
    text-align: center;

    img {
   /*     width: 80%;*/
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &:hover {
        cursor: zoom-in;
    }
}

.image-thumb-container {
    cursor:pointer;
    /*border: 2px solid rgba($main-color, .3) !important;*/
    transition: all 400ms ease;
    &:hover {
        /*border: 2px solid $main-color !important;*/
    }
    img {
        border: none !important;
    }
}
*:focus {
    outline: none;
}

#product-detail {
    .slick-slide {
        margin: 10px 0;
    }
    .image-thumb {
        pointer-events: all !important;
    }
    .next, .prev {
        font-size: 24px;
        cursor:pointer;
        color: rgba($main-color, .8);
        transition: all 300ms ease;
        &:hover {
            color: $main-color;
        }
    }

}

i.toggle-wishlist {

    display: block;
    font-size: 2rem;
    margin-top: 15px;
    color: $main-color;

}
.ui.dropdown .menu {
    z-index: 99999;
}

.qty-group {
    position: relative;
    label {
        position: absolute;
        top: 4px;
        left: 6px;
        font-size: 10px;
        text-transform: uppercase;
    }
    .qty-up {
        position: absolute;
        bottom: 4px;
        right: -3px;
    }
    .qty-down {
        position: absolute;
        bottom: 4px;
        left: 9px;

    }
    .qty-field {
        text-align: center;
        padding-top: 29px;
        padding-bottom: 18px;
        border-radius: 6px;
        outline: none !important;
    }

}
.variant-select {
    border: 1px solid #ced4da;
    border-radius: 6px;
    padding-top: 18px;
    padding-bottom: 4px !important;
    padding-left: 6px;
    label {
        position: absolute;
        top: 4px;
        left: 6px;
        font-size: 10px;
        text-transform: uppercase;
    }
}


.read-more {
    display: none;
}







/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.variants {
    .variant {


            @extend .px-2;

        .image-container {
            border: 2px solid $white;

        }
        &:hover, &.active-variant {
            .image-container {
                border: 2px solid $pastel-2-accent;
            }
        }
    }
}

.order-btn {
    background-color: $main-color;
    color: $white;
    padding: 10px 40px;
    /*border-radius: 5px;*/
    border-radius: 0px;
    display: inline-block;
    font-size: 18px;
    text-decoration: none !important;
    transition: all 300ms ease-in-out;
    &:hover {
        background-color: $black;
        color: $white;
    }
}

section#related-products {
    @extend .py-5;
    /*background-color: rgba(244, 237, 229, 0.5);*/
    .uk-position-small[class*=uk-position-center-left]{
        background-color: $pastel-2;
        color: $white;
        padding: 11px 20px ;
        transform: translateX(-120%) translateY(30px);
        font-size: 25px;
    }
    .uk-position-small[class*=uk-position-center-right] {
        background-color: $pastel-2;
        color: $white;
        padding: 11px 20px ;
        transform: translateX(120%) translateY(30px);
        font-size: 25px;
    }
    .new-content > div:first-of-type {
        text-align: center;
        @extend .mb-5;
    }
    h2 {
        display:inline-block;
        margin: 0 auto;
        text-align: center;
        text-transform: uppercase;
        position: relative;
        //border-bottom: 3px dashed $pastel-2;
    }
    li {
        padding-top: 40px;

        .new-product-item {
            /*position: relative;*/
            /*display: block;*/
            &:before {
                content: "";
                position: absolute;
                top: -30px;
                left: calc(50% - 50px);
                width: 50%;
                padding-bottom: 24%;
                background-image: url(/images/tape.svg?052e661…);
                background-size: 100%;
                background-position: bottom center;
                z-index: 999;
                background-repeat: no-repeat;
                transform-origin: center;
                opacity: .8;
                display: none !important;
            }

        }
        &:nth-child(2n) .new-product-item:before {
            //left: 50px;
            transform: scaleX(-1);
        }
        &:nth-child(3n) .new-product-item:before {
            //left: 50px;
            transform:  rotate(20deg);
        }
        &:nth-child(4n) .new-product-item:before {
            //left: 50px;
            transform: rotate(-20deg) scaleX(-1);
        }
    }
}


section#secondary-designs {
    background-color: $pastel-3;
    background-image: url(/images/ideas.svg);
    background-repeat: no-repeat;
    background-size: 300px;
    background-position: 0 -45px ;
    height: 240px;
    @extend .py-4;
    @extend .d-flex;
    @extend .align-items-center;
    h3 {
        font-size: 42px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: normal;
    }
    h4 {
        margin-top: -15px;
        font-size: 32px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
    }
.uk-button {
    box-shadow: 0 0 15px $pastel-3-accent;

}

}



#product-detail {
    padding-top: 70px;
}

#product-detail .product-info-div {
    margin-left: 0;
    margin-right: 0;
}

#product-detail .product-content > .row {
    margin-right: -30px;
}

#product-detail .product-content .product-title, #product-documents h2 {
    font-family: $main-font9;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 46px;
    letter-spacing: -1.2px;
    color: $main-color;
}

#product-documents h2 {
    border-bottom: 1px solid rgba(7,41,57,0.1);
}

#product-documents .video-poster-img {
    text-align: center;
}

#product-documents .video-poster-img img {
    max-height: 100svh;
}

.productvideo {
    position: relative;
}

#product-detail .product-content .product-title .new-label,
#product-detail .product-content .product-title .promo-label {
    border-radius: 14px;
    background-color: $blue-light;
    padding: 3px 10px 3px 10px;
    color: $white;
    font-family: $main-font5;
    font-size: 10px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0px;
    display: inline-block;
    position: relative;
    top: -4px;
    left: 8px;
}

#product-detail .product-content .description-container {
    margin-top: 10px;
}

#product-detail .product-content .read-more {
    margin-top: 20px;
    margin-bottom: 40px;
    font-family: $main-font6;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-decoration-line: underline;
    text-underline-position: under;
    color: $main-color;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
}

#product-detail .product-content .read-more a {
    font-family: $main-font6;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-decoration-line: underline;
    text-underline-position: under;
    color: $main-color;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
}

#product-detail .product-content .read-more a:hover,
#product-detail .product-content .read-more a:focus {
    color: $blue-light;
}

#product-detail .product-content .productdesc .description p,
#product-detail .product-content .productdesc .description ul {
    margin-top: 0px;
    margin-bottom: 0px;
}

#product-detail .product-content .product-detail-price {
    font-family: $main-font7 !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    color: $main-color !important;
}

#product-detail .product-content .product-detail-price .inclbtwlabel {
    color: rgba(0, 0, 0, 0.50);
    font-family:$main-font6;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
}

#product-detail .product-content .aantal-label, .product-add-to-cart-mobile .aantal-label {
    font-family: $main-font6;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    color: $main-color;
    margin-bottom: 5px;
}

#product-detail .product-content .prodqty .qty-group, .product-add-to-cart-mobile .prodqty .qty-group {
    border: none !important;
}

#product-detail .product-content .prodqty input, .product-add-to-cart-mobile .prodqty input {
    border: 1px solid #BBB9C3;
    border-radius: 3px;
    font-family: $main-font1;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding-left: 15px;
    padding-top: 7px;
    padding-bottom: 7px;
    height: 50px !important;
    text-align: left !important;
}

#product-detail .product-content .prodqty .qty-group .qty-up, .product-add-to-cart-mobile .prodqty .qty-group .qty-up {
    position: absolute;
    bottom: 18px;
    right: 0px;
}

#product-detail .product-content .prodqty .qty-group .qty-down, .product-add-to-cart-mobile .prodqty .qty-group .qty-down {
    position: absolute;
    bottom: 6px;
    left: auto;
    right: 16px;
}


#product-detail .product-content .order-btn, .product-add-to-cart-mobile .order-btn {
    color: $white;
    border-radius: 60px;
    background-color: $blue-dark;
    border-color: $blue-dark;
    padding: 11px 28px;
    font-family: $main-font7;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
/*    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;*/

    transition: all .3s;
    position: relative;
    overflow: hidden;
    z-index: 1;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        border-radius: 60px;
        z-index: -2;
    }
    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: darken($blue-light, 15%);
        transition: all .3s;
        border-radius: 60px;
        z-index: -1;
    }
    &:hover {
        color: #FFF;
        &:before {
            width: 100%;
        }
    }

}

#product-detail .product-content .order-btn:hover,
#product-detail .product-content .order-btn:focus,
.product-add-to-cart-mobile .order-btn:hover,
.product-add-to-cart-mobile .order-btn:focus {
    //background-color: $blue-light;
    border-color: $blue-light;
}

#product-detail .product-content .aantal-button,
.product-add-to-cart-mobile .aantal-button {
    max-width: 400px;
}

#product-detail .product-content .prodqty,
.product-add-to-cart-mobile .prodqty {
    flex: 0 0 27%;
    max-width: 27%;
}

#product-detail .product-content .prodbtn,
.product-add-to-cart-mobile .prodbtn {
    flex: 0 0 73%;
    max-width: 73%;
}

#product-detail .product-content .prodqty,
.product-add-to-cart-mobile .prodqty {
    padding-right: 10px;
}

#product-detail .product-content .prodbtn,
.product-add-to-cart-mobile .prodbtn {
    padding-left: 10px;
}

.product-usps-section > .container {
    max-width: 1200px;
}

.product-usps-section hr {
    border-top: 1px solid rgba(7, 41, 57, 0.10);
    margin-top: 60px;
}


#product-documents {
    padding-top: 70px;
}


section#related-products {
    padding-top: 15px !important;
    padding-bottom: 110px !important;
}


section#related-products .container-fluid {
    text-align: center;
    position: relative;
    z-index: 0;
}

section#related-products .container-fluid::before {
    content: '';
    background: url('/images/dark-bg-layer.svg') no-repeat 50% 50%;
    background-size: cover;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

section#related-products .container-fluid h2 {
    font-family: $main-font9;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 46px;
    letter-spacing: -1.2px;
    color: $blue-light;
    text-transform: none;
    padding-top: 25px;
    padding-bottom: 25px;
}

section#related-products .container-fluid {
    position: relative;
}



.product-detail-page .uk-sticky-placeholder + .header-general-top {
    display: none !important;
}

section#related-products > div.container {
    padding-top: 20px;
}


.circle {
    stroke: $blue-light;
    stroke-dasharray: 650;
    stroke-dashoffset: 650;
    -webkit-transition: all 0.5s ease-in-out;
    opacity: 0.3;
}

.playbutton a {
    /*  border: 1px solid red;*/
    position: absolute;
    //top: calc(50% + 55px);
    top: calc(50% + 0px);
    left: calc(50% + 0px);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 1;
    -webkit-transition: all 0.5s ease;
    width: 70px;
    height: 70px;

    .triangle {
        -webkit-transition: all 0.7s ease-in-out;
        stroke-dasharray: 240;
        stroke-dashoffset: 480;
        stroke: $blue-light;
        transform: translateY(0)
    }

    &:hover {

        .triangle {
            stroke-dashoffset: 0;
            opacity: 1;
            stroke: $blue-light;
            animation: nudge 0.7s ease-in-out;

            @keyframes nudge{
                0% {
                    transform: translateX(0)
                }
                30% {
                    transform: translateX(-5px)
                }
                50% {
                    transform: translateX(5px)
                }
                70% {
                    transform: translateX(-2px)
                }
                100% {
                    transform: translateX(0)
                }
            }
        }

        .circle {
            stroke-dashoffset: 0;
            opacity: 1;
        }

    }
}






@media (min-width: 768px) {
    .product-add-to-cart-mobile {
        display: none;
    }
}

@media (min-width: 1200px) {
    #product-detail > div.container, #product-documents > div.container {
        max-width: 1230px;
    }

    #product-detail .product-info-div > .col-md-5 {
        flex: 0 0 39.3333333333%;
        max-width: 39.3333333333%
    }

    #product-detail .product-info-div > .col-md-7 {
        flex: 0 0 60.6666666667%;
        max-width: 60.6666666667%;
        padding-left: 45px;
    }

    #product-detail .product-info-div .product-images > div > div.col-md-2 {
        flex: 0 0 13.91%;
        max-width: 13.91%
    }

    #product-detail .product-info-div .product-images > div > div.col-md-10 {
        flex: 0 0 86.09%;
        max-width: 86.09%
    }

}

@media (max-width: 959px) {
    section#related-products {
        padding-top: 0px !important;
        padding-bottom: 40px !important;
    }

    section#related-products li {
        padding-top: 0px;
    }

    #product-documents {
        padding-top: 30px;
    }
}


@media (min-width: 1440px) {
    #product-detail .main-image img {
        height: 499px;
    }
}


@media (max-width: 767px) {
    .productlinksblock {
        margin-top: 30px;
    }

    .product-add-to-cart-desktop {
        display: none;
    }

    .product-add-to-cart-mobile {
        margin-left: 0;
        margin-right: 0;
    }


    #product-detail .product-info-div {
        margin-left: -15px;
        margin-right: -15px;
    }

    #product-detail {
        padding-top: 40px;
    }

}


@media (max-width: 603px) {
    .alert a {
        display: block;
        margin-top: 20px;
    }
}

@media (max-width: 500px) {
    .product-detail-page h1 .nav-back {
        margin-top: 20px;
    }
}
