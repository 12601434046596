.blog-overview, .blog-detail {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 70px;
    padding-bottom: 70px;
}


.blog-overview a, .blog-overview a:hover, .blog-overview a:focus {
    text-decoration: none !important;
    color: #090914;
}

.blog-overview .btn, .blog-link-overview, .prev-link-wrapper a, .next-link-wrapper a {
    color: #fff !important;
    border-radius: 60px;
    background-color: #3FBDEE !important;
    border-color: #3FBDEE !important;
    padding: 11px 28px;
    font-family: 'Plus Jakarta Sans Bold';
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    box-shadow: none !important;
    position: relative;
    overflow: hidden;
    z-index: 1;
    text-decoration: none !important;
    display: inline-block;
}

.blog-overview .btn::before, .blog-link-overview::before, .prev-link-wrapper a::before, .next-link-wrapper a::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: black;
    transition: all 0.3s;
    border-radius: 60px;
    z-index: -1;
}

.blog-overview .btn:hover::before,
.blog-link-overview:hover::before,
.prev-link-wrapper a:hover::before,
.next-link-wrapper a:hover::before {
    width: 100%;
}

.prev-link-wrapper,
.next-link-wrapper {
    margin-top: 40px;
}

.blog-overview .creatic-blog-article-item-wrapper {
    margin-bottom: 40px;
}

.blog-overview .creatic-blog-article-item-wrapper > div {
    border: 1px solid rgba(7, 41, 57, 0.1);
}

.blog-overview .creatic-blog-article-item-wrapper .creatic-blog-article {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
}

.blog-detail .blog-content {
    padding-top: 20px;
}

.blog-detail .blog-content > .row + .row {
    margin-top: 20px;
}

.blog-content #photoGallery .row > div {
    margin-bottom: 30px;
}

@media (max-width: 767px) {
    .blog-overview, .blog-detail {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    #blogDetailTitle {
        margin-top: 20px;
    }

    .panel-left + .panel-right,
    .panel-right + .panel-left {
        margin-top: 20px;
    }
}
