#overview-products {
    div h2 {
        padding-top: 10px !important;
    }

    .product-square {

        .image-container {
            height: 330px;

            img {
                object-fit: cover;
                max-width: 100%;
                max-height: 330px;
            }

            @media (max-width: 767px) {
                img {
                    max-height: 200px;
                }
            }
        }

        @media (max-width: 767px) {
            .image-container {
                max-height: 200px;
            }
        }

        .product-list-item {
            border: none;
            background: none;

            .product-info {
                background: none;
                box-shadow: none;
                height: unset;

                .product-title {
                    font-size: 16px !important;
                    max-width: unset;
                }

                .product-price {
                    color: $black;
                    font-weight: 300;
                }
            }
        }
    }

    .uk-slider-items {
        margin-left: -15px;
    }

    .to-webshop-btn {
        display: flex !important;
        margin: 60px auto 110px auto;
        font-size: 16px !important;
    }

    @media (max-width: 767px) {
        .to-webshop-btn {
            font-size: 14px !important;
        }
    }

    @media (max-width: 959px) {
        .to-webshop-btn {
            margin: 60px auto 40px auto;
        }
    }

    > div {
        padding-left: 0;
        padding-right: 0;

        > h2 {
            padding-top: 70px;
            padding-bottom: 30px;
        }
    }

    .home-offer-slider-desktop {

        .uk-slidenav-previous,
        .uk-slidenav-next {
            color: $black;
        }

        .uk-slider-nav {
            margin-top: 0;
        }
    }
}

@media (max-width: 959px) {
    #product-detail #overview-products {
        padding-top: 0 !important;
    }
}
