/*html, body {
    overflow-x: hidden;
}*/

body {
    font-family: $main-font6;
    color: $main-color;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    overflow-x: hidden;
}

b, strong {
    font-family: $main-font7 !important;
    font-weight: 700 !important;
}

.uk-button.uk-button-default {
    width: 271px;
    padding-top:10px;
    padding-bottom: 10px;
    border-radius: 7px;
    background-color: $white;
    transition: all 300ms ease-in-out;
    font-size: 18px;
    &:hover {
        background-color: $black;
        color: $white;
    }
}
.uk-button.btn-dark {
    background-color: $main-color;
    color: $white;
}

input:not([type="checkbox"]):not([type="radio"]) {
    /*border: 1px solid $pastel-3-accent;*/
    /*border: 1px solid $brown-v1;*/
    border: 1px solid #C4C4C4;
    background-color: transparent;
    padding-top: 5px;
    padding-bottom: 5px;

}

select, textarea {
    background-color: transparent !important;
}

input:not([type="checkbox"]):not([type="radio"]), select {
    height: 46px !important;
}

.uk-select:not([multiple]):not([size]) {
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 20px;
    background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='16' height='16' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='black'/></g></svg>");
    background-repeat: no-repeat;
    background-position: 94% 50%;
    /*border: 1px solid $pastel-3-accent;*/
    border: 1px solid $brown-v1;
    background-color: transparent;
    padding-top: 5px;
    padding-bottom: 5px;
}

.select2-container {
    width: 100% !important;
    .selection {
        height: 38px;
        .select2-selection {
            height: 38px;
            @extend .form-control;
            /*border: 1px solid $pastel-3-accent;*/
            border: 1px solid $brown-v1;
            border-radius: 0;
            background-color: transparent !important;
            padding-top: 5px;
            padding-bottom: 5px;
        }
         .select2-selection__arrow {
             height: 38px !important;
         }
    }
}

.price-input  {
    position: relative;
    &:before {
        position: absolute;
        content: "€";
        top: 8px;
        left: 22px;
        /*color: $pastel-3-accent;*/
        /*color: $brown-v1;*/
        color: $main-color;
    }
    input {
        text-align: right;
    }
}


.category-detail-filter-left .price-input  {
    position: relative;
    &:before {
        position: absolute;
        content: "€";
        top: 18px;
        left: 21px;
        /*color: $pastel-3-accent;*/
        /*color: $brown-v1;*/
        color: $main-color;
    }
    input {
        text-align: left;
        padding-left: 20px;
        padding-top: 23px !important;
    }
}

.break {
    flex-basis: 100%;
    height: 0;
}

.qty-selector {
    position: relative;
    .qty-select-open {
        height: 45px;
        width: 45px;
        /*background-color: $pastel-3;
        border: 1px solid $pastel-3;*/
        background-color: $blue-dark;
        border: 1px solid $blue-dark;
        color: $white;
        text-align: center;
        margin-left: -4px;
        padding: 10px 0;
        cursor: pointer;
    }
    .qty-field {
        /*border: 1px solid $pastel-3;*/
        border: 1px solid #ccc;
        height: 45px; width: 80px;
        text-align: center;
        vertical-align: top;

    }
    .qty-select-options {
        position: absolute;
        left: 0;
        z-index: 99;
        top: 100%;
        background-color: $white;
        /*border: 1px solid $pastel-3;*/
        border: 1px solid #ccc;
        border-top: none;
        width: 125px;
        height: 200px;
        overflow-y: auto;
        transform: scaleY(0);
        transform-origin: top;
        opacity: 0;
        transition: all 300ms ease-in-out;

        &.open {
            transform: scaleY(1);
            opacity: 1;

        }
        /* width */
        &::-webkit-scrollbar {
            width: 10px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            /*background: $pastel-3;*/
            background: $blue-dark;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
           /* background: $pastel-3;*/
            background: $blue-light;
        }
        scrollbar-width: thin;
        /*scrollbar-color: $pastel-3 #f1f1f1;;*/
        scrollbar-color: $blue-light #f1f1f1;
        div {
            &:hover {
                /*background-color: rgba($pastel-3, .6);*/
                background-color: rgba($blue-light, .6);
            }
        }
    }
}

.page-btn {

    border-radius: 4px;
    border: 1px solid #DFE3E8;
    background: #FFF;
    margin-right: 10px;
    padding: 0;
    height: 32px;
    width: 32px;
    text-align: center;
    /*padding: 3px 4px 7px 4px;*/

    span, a {
        color: $blue-dark !important;
        font-family: $main-font3;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        /*padding: 3px 4px 7px 4px;*/
        /*padding: 5px 13px 7px 13px;*/
        display: inline-block;
        width: 32px;
        position: relative;
        left: -1px;
    }

    a:hover, a:focus {
        text-decoration: none;
    }

}

.pager .disabled {
    background: #E3E3E3 !important;
}

.pager .prev-btn, .pager .next-btn {
    border-radius: 4px;
    border: 1px solid #DFE3E8;
    background: #FFF;
    margin-right: 10px;
    padding: 0;
    height: 32px;
    width: 32px;
    text-align: center;
    /*padding: 3px 4px 7px 4px;*/

    span, a {
        /*padding: 5px 13px 7px 13px;*/
        display: inline-block;
        line-height: 32px;
        width: 32px;
    }

    a:hover, a:focus {
        text-decoration: none;
    }

}

.page-btn:hover,
.page-btn:focus,
.prev-btn:hover,
.prev-btn:focus,
.next-btn:hover,
.next-btn:focus {
    border: 1px solid $blue-dark;
}

.disabled {
    opacity: 0.5;
}

.disabled:hover,
.disabled:focus {
    border: 1px solid #DFE3E8;
}


.page-btn.active {
    /*background: $brown;*/

    border: 1px solid $blue-dark;

    span, a {
        color: $blue-dark !important;
        font-family: $main-font2;
    }
}

.pager {
    /*border-top: 1px solid $brown;*/
    padding-top: 60px;
    padding-bottom: 100px;

    .prev-btn {
        margin-right: 10px;

        a {
            background: transparent !important;
            color: $black !important;
        }

        img {
            width: 12px;
            height: 12px;
            object-fit: contain;
            position: relative;
            top: -2px;
            /*opacity: 0.4;*/
            left: -1px;
        }
    }

    .next-btn {
        margin-left: 0px;

        a {
            background: transparent !important;
            color: $black !important;
        }

        img {
            width: 12px;
            height: 12px;
            object-fit: contain;
            position: relative;
            top: -2px;
            /*opacity: 0.4;*/
        }
    }
}



.width-l > div.container {
    max-width: 1137px;
}

.img-txt img, .txt-img img {
    border-radius: 60px;
}

.width-l h2 {
    /*max-width: 239px;*/
}


.img-txt > div.container > div.row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.blue {
    color: $blue-light;
}

.dark-bg {
    background: $blue-dark;
}

.dark-bg p,
.dark-bg li {
    color: $white;
}

.txt-link {
    text-decoration: underline;
    text-underline-position: under;
    color: $blue-light;
}

.txt-link:hover,
.txt-link:focus {
    color: $blue-dark;
}

.form-control:focus, .select2-container .selection .select2-selection:focus {
    box-shadow: none !important;
}

:focus-visible {
    outline: 1px solid $blue-light !important;
}

.country-select{
    display: block !important;
}

@media (min-width: 1200px) {
    .user-details > .container, .account-orders > .container, .account-order > .container {
        max-width: 1230px;
    }
}

@media (min-width: 1440px) {
    .img-txt .content {
        padding-left: 85px;
    }

    .img-txt img {
        height: 370px;
        object-fit: cover;
    }
}


@media (max-width: 1199px) {
    .container {
        max-width: 100%;
    }
}


@media (max-width: 991px) {
    .footer-col1, .footer-col2 {
        padding-bottom: 40px;
    }
}


@media (max-width: 767px) {
    .footer-col1, .footer-col2, .footer-col3, .footer-col4 {
        text-align: center !important;
    }
    .footer-col3 {
        padding-bottom: 40px;
    }

}
