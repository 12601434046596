@media(min-width: 960px) {
    .overons-1 {
        padding-bottom: 70px;
    }

    .overons-2 {
        padding-bottom: 70px;
    }

}


@media (min-width: 1200px) {
    .overons-bottom .product-usps-section > section.container {
        max-width: 1230px;
    }
}


@media(max-width: 959px) {
    .overons-1 {
        padding-bottom: 40px;
    }

    .overons-2 {
        padding-bottom: 20px;
    }

}


@media(max-width: 767px) {
    .overons-1 > div > div > div:nth-child(1) {
        order: 2; -webkit-order: 2;
        padding-top: 20px;
    }
    .overons-1 > div > div > div:nth-child(2) {
        order: 1; -webkit-order: 1;
        padding-top: 0px;
    }
    .overons-2 > div > div > div:nth-child(1) {
        order: 2; -webkit-order: 2;
        padding-top: 20px;
    }
    .overons-2 > div > div > div:nth-child(2) {
        order: 1; -webkit-order: 1;
    }
}
